import React from 'react';
import {
    createBrowserRouter,
    RouterProvider,
} from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import ErrorPage from './ErrorPage';
import Main from './pages/Main';
import Map2021 from './pages/Map2021';
import Map2022 from './pages/Map2022';
import HallOfFame from './pages/HallOfFame';

const router = createBrowserRouter([
    {
        path: '/',
        element: <App />,
        errorElement: <ErrorPage />,
        children: [
            {
                index: true,
                element: <Main />,
            },
            {
                path: '/2022',
                element: <Map2022 />,
            },
            {
                path: '/2021',
                element: <Map2021 />,
            },
            {
                path: '/hall-of-fame',
                element: <HallOfFame />,
            },
        ],
    },
]);

const root = createRoot(document.getElementById('root'));
root.render(<React.StrictMode><RouterProvider router={router} /></React.StrictMode>);
