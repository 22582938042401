import React from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import styles from './App.module.scss';

function App() {
    const location = useLocation();
    return (
        <div className="App">
            <div className={styles.menu}>
                {/* <div style={{ float: 'right', marginTop: 18, marginRight: 14 }}>
                <BurgerMenu />
            </div> */}
                <h1 style={{ paddingTop: 10, paddingLeft: 10, color: '#928d00ff' }}>
                    <img style={{ width: '31px', paddingRight: 5 }} src="/logo_plain_opti.svg" alt="logo" />
                    Ølgolf
                </h1>
                <div style={{ clear: 'both', marginLeft: 24 }}>
                    <ul>
                        <li><Link className={location.pathname === '/' ? styles.active : null} to="/">2023</Link></li>
                        <li><Link className={location.pathname === '/2022' ? styles.active : null} to="2022">2022</Link></li>
                        <li><Link className={location.pathname === '/2021' ? styles.active : null} to="2021">2021</Link></li>
                        {/* <li> https://moderncss.dev/custom-select-styles-with-pure-css/
                            <div className={styles.select}>
                                <select id="standard-select">
                                    <option value="Select">select</option>
                                    <option value="Option 2">Option 2</option>
                                    <option value="Option 3">Option 3</option>
                                    <option value="Option 4">Option 4</option>
                                    <option value="Option 5">Option 5</option>
                                    <option value="Option length">
                                        Option that has too long of a value to fit
                                    </option>
                                </select>
                                <span className={styles.focus} />
                            </div>
                        </li> */}
                        <li><Link className={location.pathname === '/hall-of-fame' ? styles.active : null} to="hall-of-fame">Hall of Fame</Link></li>
                    </ul>
                </div>
            </div>
            <div style={{ backgroundColor: '#f0eee8ff' }}>
                <Outlet />
            </div>
        </div>
    );
}

export default App;
